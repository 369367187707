import { AutomatitCarousel } from './automatit_carousel.m';

export const initHomeCaro = () => {
    const homeCaroImgs = [
        {src: "/static/images/slideshow_1.png", alt: ""},
        {src: "/static/images/slideshow_2.png", alt: ""},
        {src: "/static/images/slideshow_3.png", alt: ""},
        {src: "/static/images/slideshow_4.png", alt: ""},
        {src: "/static/images/slideshow_5.png", alt: ""},
    ];

    AutomatitCarousel({
        element: document.querySelector('#home-slideshow'),
        images: homeCaroImgs,
        imagesAsBackgrounds: true,
        showDots: false,
        useChevrons: false,
    })
}