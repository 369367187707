import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from  './util/mapsHelper'

export const initLocationCaro = () => {
    const homeCaroImgs = [
        {src: "/static/images/slideshow-image01.jpg", alt: ""},
        {src: "/static/images/slideshow-image02.jpg", alt: ""},
        {src: "/static/images/slideshow-image03.jpg", alt: ""},
        {src: "/static/images/slideshow-image04.jpg", alt: ""},
        {src: "/static/images/slideshow-image05.jpg", alt: ""},
    ];

    AutomatitCarousel({
        element: document.querySelector('.location_caro'),
        images: homeCaroImgs,
        imagesAsBackgrounds: true,
        showDots: false,
        useChevrons: false,
    })
}

export const initMap = () => {
    const mapHelper = getMapsHelper();

    mapHelper.ready()
        .then(() => {
            const theMap = mapHelper.createMap({
                element: document.querySelector('#map_canvas'),
                locationElementSelector: '#map_canvas',
            })
            
            google.maps.event.addListenerOnce(theMap, 'idle', () => {
                theMap.setZoom(15);
            })
        })
}