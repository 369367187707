import { initHomeCaro } from './home';
import { initMap, initLocationCaro } from './facility'
import { contactSubmit } from './contact.js';

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
 }

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
	  var el = this;
  
	  do {
		if (el.matches(s)) return el;
		el = el.parentElement || el.parentNode;
	  } while (el !== null && el.nodeType === 1);
	  return null;
	};
}

if(document.querySelector('#home-slideshow')) {
	initHomeCaro();
}

if(document.querySelector('.location_caro')) {
	initLocationCaro();
}

if(document.querySelector('#map_canvas')) {
	initMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
